import React from "react"
import { useFormContext } from "react-hook-form"
import { InputField, Select, Checkbox, TextArea, RadioGroup, } from "../FormElements"
import { countries, graduationYears, areaOfStudies } from "../../data"

const CollegeStudentForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <InputField
        label="School Name"
        name="schoolName"
        type="text"
        register={register}
        error={errors.schoolName?.message}
        required
      />
      <InputField
        label="School Website URL"
        name="schoolWebsiteUrl"
        type="text"
        register={register}
        error={errors.schoolWebsiteUrl?.message}
        required
      />
      {/* <InputField
        type="text"
        name="city"
        label="City"
        required
        register={register}
        error={errors.city?.message}
      />
      <InputField
        type="text"
        name="state"
        label="State"
        required
        register={register}
        error={errors.state?.message}
      /> */}
      <Select
        name="country"
        label="Country"
        required
        valueKey="name"
        options={countries}
        register={register}
        error={errors.country?.message}
      />
      <RadioGroup
        control={control}
        label="Do you use Phi in class?"
        name="usePhi"
        required
        group={["Yes", "No"]}
        error={errors.usePhi?.message}
      />
      <InputField
        label="If yes, what's your Professor's name"
        name="teachersName"
        type="text"
        register={register}
        error={errors.teachersName?.message}
      />
      <Select
        name="graduationYear"
        label="Graduation Year"
        required
        options={graduationYears}
        register={register}
        error={errors.graduationYear?.message}
      />
      <Select
        name="areaOfStudy"
        label="Area of study / degree"
        required
        options={areaOfStudies}
        register={register}
        error={errors.areaOfStudy?.message}
      />
      {/* <TextArea
        label="What are you going to use Phi for?"
        name="use"
        required
        register={register}
        rows={5}
        error={errors.use?.message}
      /> */}
      <Checkbox
        control={control}
        error={errors.over13?.message}
        name="over13"
        label="I am a Student, or an IT Administrator acting on behalf of a Qualified Educational Institution, or a team mentor in a qualified student competition, or I have parental responsibility for a Student, or I have the consent from the person with parental responsibility. I agree to only use this application for the following purposes: classes, personal learning projects, school clubs/organizations, and academic research. I will not use this plan for government, commercial, or any other organizational use."
      />
      <Checkbox
        control={control}
        error={errors.usageAgreement?.message}
        name="usageAgreement"
        label={
          <>
            I acknowledge and agree that, as per Section V(B) of the{" "}
            <a href="/terms-of-service" target="_blank">
              Terms of Service
            </a>
            , documents and files created or handled under this account can be
            automatically shared with Phenometry for viewing, for the purpose of
            improving Phi and offering support and guidance to users. Phenometry
            will not publicize or share your documents with third parties
            without your written permission.
          </>
        }
      />
      <Checkbox
        control={control}
        error={errors.termsOfService?.message}
        name="termsOfService"
        label={
          <>
            I agree to the{" "}
            <a href="/terms-of-service" target="_blank">
              Terms of Service
            </a>{" "}
            and confirm that the above information is accurate and truthful.
          </>
        }
      />
    </>
  )
}

export default CollegeStudentForm

import styled from "styled-components"
import { LabelWrapper } from "../FormElements/styles"

export const Form = styled.form`
  display: grid;
  /* grid-template-columns: 1fr; */
  grid-auto-flow: row;
  gap: 2.4rem;

  ${LabelWrapper} {
  }

  footer {
    /* grid-area: footer; */
    margin-top: 4rem;
    text-align: right;
  }

  button {
    justify-self: start;
  }
`

export const StepTitle = styled.p`
  text-align: center;
  text-transform: uppercase;
  padding: 2rem 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey};
  user-select: none;

  &:before,
  &:after {
    content: "";
    height: 1px;
    width: 3rem;
    margin: 1rem;
    background-color: ${({ theme }) => theme.colors.greyDark};
  }
`

export const StepWrapper = styled.div`
  transition: transform 0.2s ease, opacity 0.2s ease;
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 4rem;
  }

  ${({ visible }) =>
    visible
      ? `
    opacity: 1;
    transform: translateX(0);
  `
      : `
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none;
    height: 1px;
  `}
`

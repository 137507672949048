import React, { useEffect, useState } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Button from "../Button"
import { P } from "../../styles/typography"
import { Form, StepTitle } from "./style"

import InitialForm from "./InitialForm"
import EducatorForm from "./EducatorForm"
import SchoolStudentForm from "./SchoolStudentForm"
import CollegeStudentForm from "./CollegeStudentForm"
import { removeFields, convertStringBool } from "../../utils/helpers"
import addToMailchimp from "gatsby-plugin-mailchimp"

const common = yup.object().shape({
  schoolName: yup.string().required("This is field is required"),
  schoolWebsiteUrl: yup.string().required("This is field is required"),
  // city: yup.string().required("This is field is required"),
  // state: yup.string().required("This is field is required"),
  country: yup.string().required("This is field is required"),
  usePhi: yup
    .string()
    .oneOf(["Yes", "No"])
    .required("This field is required"),
  // use: yup.string().required("This is field is required"),
  usageAgreement: yup
    .boolean()
    .oneOf([true], "This field is required")
    .required("This field is required"),
  termsOfService: yup
    .boolean()
    .oneOf([true], "This field is required")
    .required("This field is required"),
})

const commonStudent = common.shape({
  graduationYear: yup
    .number()
    .required("This field is required")
    .positive()
    .integer(),
  over13: yup
    .boolean()
    .oneOf([true], "This field is required")
    .required("This field is required"),
})

const validationSchema = [
  yup.object().shape({
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    email: yup
      .string()
      .email("The email is not valid")
      .required("This field is required"),
    educationType: yup.string().required("This field is required"),
    schoolLevel: yup.string().required("This field is required"),
    subscribeToNewsletter: yup.boolean(),
  }),
  common.shape({
    titlePosition: yup.string().required("This field is required"),
    // mobileNumber: yup.string().required("This field is required"),
    whatDoYouTeach: yup.string().required("This field is required"),
    // useCad: yup
    //   .string()
    //   .oneOf(["Yes", "No"])
    //   .required("This field is required"),
    currentlyMember: yup
      .boolean()
      .oneOf([true], "This field is required")
      .required("This field is required"),
  }),
  commonStudent,
  commonStudent.shape({
    areaOfStudy: yup.string().required("This field is required"),
  }),
]

function getFormContent(form) {
  switch (form) {
    case "educator":
      return <EducatorForm />
    case "schoolStudent":
      return <SchoolStudentForm />
    case "collegeStudent":
      return <CollegeStudentForm />
    default:
      return <InitialForm />
  }
}

function newActiveForm(eduType, school) {
  if (eduType === "Educator") {
    return "educator"
  }
  if (eduType === "Student" && school === "Grade School (K-12)") {
    return "schoolStudent"
  }
  if (eduType === "Student" && school === "College or University") {
    return "collegeStudent"
  }
  return "initial"
}

const EducationForm = () => {
  const forms = ["initial", "educator", "schoolStudent", "collegeStudent"]
  const [activeForm, setActiveForm] = useState(forms[0])
  const currentValidationSchema = validationSchema[forms.indexOf(activeForm)]
  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(currentValidationSchema),
    mode: "onChange",
  })
  const {
    handleSubmit,
    reset,
    trigger,
    getValues,
    formState,
    register,
    control,
  } = methods
  const { errors, isValid, isDirty } = formState
  const [serverResponse, setServerResponse] = useState(null)
  const [activeStep, setActiveStep] = useState(0)

  const nextStep = (e) => {
    e.preventDefault()
    setActiveStep((s) => s + 1)
    const nextForm = getValues(["educationType", "schoolLevel"])
    setActiveForm(newActiveForm(nextForm[0], nextForm[1]))
    window.scrollTo(0, 0)
  }

  const onSubmit = (data) => {
    let body = removeFields(["usageAgreement", "termsOfService"], data)
    console.log(data.educationType)
    if (data.subscribeToNewsletter === true) {
      addToMailchimp(data.email, {
        FNAME: data.firstName,
        LNAME: data.lastName,
        MMERGE3: `edu-${data.educationType}`,
        TAGS: ['SubscribedToNewsletter'],
      }).then((res) => {
        console.log(res)
      })
    }
    else {
      addToMailchimp(data.email, {
        FNAME: data.firstName,
        LNAME: data.lastName,
        MMERGE3: `edu-${data.educationType}`,
        TAGS: ['NotSubscribedToNewsletter'],
      }).then((res) => {
        console.log(res)
      })
    }
    fetch("/api/register", {
      method: `POST`,
      body: JSON.stringify(convertStringBool(body)),
      headers: {
        "content-type": `application/json`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          setServerResponse({
            status: res.status,
            message: () => (
              <P>An error occured, please refresh and try again.</P>
            ),
          })
        }
        setServerResponse({
          status: res.status,
          message: () => (
            <div>
            <P>
              Thank you for your application.
            </P>
            <P>
            You can go on and {" "}
              <a
                href="https://buy.stripe.com/28o3es4bn7gLbRu8ww"
                target="_blank"
                rel="noopener noreferrer"
              >
                Start a Trial
              </a>
              {" "} on the Professional plan, while we process your application. In a few days we will send you instructions on how to activate your educational license discount.
            </P>
            </div>
          ),
        })
        return res
      })
      .then((body) => {
        reset()
        return body
      })
  }

  return serverResponse ? (
    <>
      {serverResponse.message()}
      <Button
        css={`
          margin-top: 2rem;
        `}
        to={serverResponse.status === 200 ? "/" : "/register"}
      >
        {serverResponse.status === 200 ? "Back to homepage" : "Try again"}
      </Button>
    </>
  ) : (
    <>
      <StepTitle>Step {activeStep + 1}</StepTitle>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {getFormContent(activeForm)}
          <footer>
            {activeStep < 1 ? (
              <Button
                variant="secondary"
                disabled={!isDirty || !isValid}
                onClick={(e) => nextStep(e)}
              >
                Next Step
              </Button>
            ) : (
              <Button type="submit" disabled={!isDirty || !isValid}>
                Submit
              </Button>
            )}
          </footer>
        </Form>
      </FormProvider>
    </>
  )
}

export default EducationForm

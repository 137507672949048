import React from "react"
import { useFormContext } from "react-hook-form"
import { InputField, Select, Checkbox } from "../FormElements"

const InitialForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <InputField
        label="First Name"
        name="firstName"
        type="text"
        register={register}
        error={errors.firstName?.message}
        required
      />
      <InputField
        label="Last Name"
        name="lastName"
        type="text"
        register={register}
        error={errors.lastName?.message}
        required
      />
      <InputField
        type="text"
        name="email"
        label="Your Email"
        required
        register={register}
        error={errors.email?.message}
      />
      <Select
        name="educationType"
        label="Are you a student or educator?"
        required
        options={[
          {
            value: "Student",
          },
          {
            value: "Educator",
          },
        ]}
        register={register}
        error={errors.educationType?.message}
      />
      <Select
        name="schoolLevel"
        label="School Level"
        required
        options={[
          {
            value: "Grade School (K-12)",
          },
          {
            value: "College or University",
          },
        ]}
        register={register}
        error={errors.schoolLevel?.message}
      />
      <Checkbox
        control={control}
        error={errors.subscribeToNewsletter?.message}
        name="subscribeToNewsletter"
        label={
          <>
            Subscribe me to the newsletter!
            <br />
            (We use Mailchimp as our marketing platform. By subscribing, you
            acknowledge that your information will be transferred to Mailchimp
            for processing. Learn more about Mailchimp's privacy practices{" "}
            <a
              href="https://mailchimp.com/legal/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .)
          </>
        }
      />
    </>
  )
}

export default InitialForm

import React from "react"
import { useFormContext } from "react-hook-form"
import {
  InputField,
  Select,
  Checkbox,
  TextArea,
  RadioGroup,
} from "../FormElements"
import { countries } from "../../data"

const EducatorForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <InputField
        label="School Name"
        name="schoolName"
        type="text"
        register={register}
        error={errors.schoolName?.message}
        required
      />
      <InputField
        label="School Website URL"
        name="schoolWebsiteUrl"
        type="text"
        register={register}
        error={errors.schoolWebsiteUrl?.message}
        required
      />
      {/* <InputField
        type="text"
        name="city"
        label="City"
        required
        register={register}
        error={errors.city?.message}
      />
      <InputField
        type="text"
        name="state"
        label="State"
        required
        register={register}
        error={errors.state?.message}
      /> */}
      <Select
        name="country"
        label="Country"
        required
        valueKey="name"
        options={countries}
        register={register}
        error={errors.country?.message}
      />
      <InputField
        label="Title / Position"
        name="titlePosition"
        type="text"
        register={register}
        error={errors.titlePosition?.message}
        required
      />
      {/* <InputField
        label="Mobile phone number"
        name="mobileNumber"
        type="text"
        register={register}
        error={errors.mobileNumber?.message}
        required
      /> */}
      <InputField
        label="What do you teach?"
        name="whatDoYouTeach"
        type="text"
        register={register}
        error={errors.whatDoYouTeach?.message}
        required
      />
      {/* <RadioGroup
        control={control}
        label="Do you use CAD in your class?"
        name="useCad"
        required
        group={["Yes", "No"]}
        error={errors.useCad?.message}
      /> */}
      <RadioGroup
        control={control}
        label="Do you use (or plan to use) Phi in any of your classes?"
        name="usePhi"
        required
        group={["Yes", "No"]}
        error={errors.usePhi?.message}
      />
      {/* <TextArea
        label="What are you going to use Phi for?"
        name="use"
        required
        register={register}
        rows={5}
        error={errors.use?.message}
      /> */}
      <Checkbox
        control={control}
        error={errors.currentlyMember?.message}
        name="currentlyMember"
        label="I currently work as a faculty member or volunteer in a Qualified Educational Institution. I agree to only use this application for the following purposes: classroom instruction, student learning projects, school clubs/organizations, and academic research. I will not use this plan for government, commercial, or any other organizational use."
      />
      <Checkbox
        control={control}
        error={errors.usageAgreement?.message}
        name="usageAgreement"
        label={
          <>
            I acknowledge and agree that, as per Section V(B) of the{" "}
            <a href="/terms-of-service" target="_blank">
              Terms of Service
            </a>
            , documents and files created or handled under this account can be
            automatically shared with Phenometry for viewing, for the purpose of
            improving Phi and offering support and guidance to users. Phenometry
            will not publicize or share your documents with third parties
            without your written permission.
          </>
        }
      />
      <Checkbox
        register={register}
        error={errors.termsOfService?.message}
        name="termsOfService"
        label={
          <>
            I agree to the{" "}
            <a href="/terms-of-service" target="_blank">
              Terms of Service
            </a>{" "}
            and confirm that the above information is accurate and truthful.
          </>
        }
      />
    </>
  )
}

export default EducatorForm

import React from "react"
import SEO from "../components/seo"
import Layout from "../layouts/default"
import Page from "../components/Page"

import usePageHeader from "../hooks/usePageHeader"
import { H1, H2 } from "../styles"
import Container from "../components/Container"
import EducationForm from "../components/EducationForm"
import { css } from "styled-components"

const Register = () => {
  const [pageHeaderRef, pageHeaderHeight] = usePageHeader()

  return (
    <Layout>
      <SEO title="Phi | Get started with Phi for Students & Educators" />
      <Page as="main" pageHeaderHeight={pageHeaderHeight} columnWidth={600}>
        <Page.Header as="header" columnWidth={600} ref={pageHeaderRef}>
          <Page.Subtitle>Phi Educational License</Page.Subtitle>
          <H1 as={H2}>Application</H1>
        </Page.Header>
        <Container
          columnWidth={600}
          as="section"
          css={`
            padding-top: 4rem;
            padding-bottom: 6rem;
          `}
        >
          <EducationForm />
        </Container>
      </Page>
    </Layout>
  )
}

export default Register
